import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["fixed inset-0 z-50 bg-gray-900 bg-opacity-75 text-white flex flex-col items-center justify-center", _ctx.transactionClass.container])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : _createCommentVNode("", true)
}