
import {defineComponent} from 'vue';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Toaster from "@/composition/toaster.ts";
import Web3 from "web3";
import TransactionDialog from "@/components/TransactionDialog.vue"
import Collapsible from "@/components/Collapsible.vue"
import Footer from "@/components/Footer.vue";

const web3 = require("web3")
const ico_abi = require("@/assets/ico.json")
const testnet = false

// REAL SMART CONTRACT ADDRESS: 0xEde62Ac08D0Be6eF937c7bBda0C171b4c4432214
// TESTNET SMART CONTRACT ADDRESS: 0x6375e1fC0111D48bc4eB8f3f7023665f394750ea
// TESTNET SMART CONTRACT ADDRESS W/O time locks: 0xc652b14fDe48C3d6782FA7FA70a68c52681D3B12
const ico_address = testnet ? "0xc652b14fDe48C3d6782FA7FA70a68c52681D3B12" : "0xEde62Ac08D0Be6eF937c7bBda0C171b4c4432214"

declare global {
	interface Window {
		BinanceChain: any,
	}
}

export default defineComponent({
	name: 'Home',
	components: {Footer, TransactionDialog, Collapsible},
	data: () => ({
		theme: "",
		web3: {} as Web3,
		ico: {} as any,
		connected_as: "",
		provider_options: {},
		web3_modal: {} as any,
		max_bnb: "0.0",
		referral_code: "",
		amount_to_buy: "",
		bnb_price: null,
		bnb_price_last_update: null,
		phase: 1,
		progressPercentage: 50,
		raised_bnb: 50,
		total_raised_bnb: 500,
		money_type: "bnb",
		meld: "0.00",
		referral: false,
		bnb_cleave: {
			numeral: true,
			numeralDecimalScale: 18
		},
		overlay: {
			open: false,
			confirmations: 0,
			tx: "",
			time: 0,
			interval: -1
		},
		connected: false,
		tiers: [] as any[],
		current_tier_index: 0,
		sold: ""
	}),
	methods: {
		async getMaxWallet() {
			await this.connectWallet()
			this.amount_to_buy = this.max_bnb
		},
		prettyNumber(x: number) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		renderNumber(value: any, allowed_decimals = 18) {
			// if the provided value is not a string stringify it
			if (!(value instanceof String)) {
				value = value.toString()
			}
			let integer = "0"
			// check if the value has at least an integer part, in case it has one, prettify it
			if (value.length > allowed_decimals) {
				integer = this.prettyNumber(value.substr(0, value.length - allowed_decimals))
			}
			let decimals = "00"
			// check if the value has integer part
			if (value.length > allowed_decimals) {
				// if it has retrieve only the decimal part
				decimals = value.substr(value.length - allowed_decimals)
			} else {
				// otherwise it is a decimal number, take it all
				decimals = `${"0".repeat(allowed_decimals - value.length)}${value}`
			}
			for (let i = decimals.length - 1; i >= 2; i--) {
				// remove last zeros if any
				if (decimals[i] === "0") {
					decimals = decimals.slice(0, -1)
				} else {
					break
				}
			}
			return `${integer}.${decimals.substr(0, decimals.length > 12 ? 12 : decimals.length)}`
		},
		async connectWallet() {
			if (this.connected_as === "") {
				// show web3 modal popup
				const provider = await this.web3_modal.connect();
				// init provider and contract instance
				this.web3 = new web3(provider)
				this.ico = new this.web3.eth.Contract(ico_abi, ico_address)
				// try to connect to a wallet
				try {
					let r;
					if (!!provider.wc) {
						r = await this.web3.eth.getAccounts()
					} else {
						r = await this.web3.eth.requestAccounts()
					}
					// mark the user as connected and save the wallet address
					this.connected = true
					this.connected_as = r[0]
					// send a notification stating a successful connection
					new Toaster({
						title: `Wallet connected!`,
						message: `Successfully connected as ${r[0]}`,
						type: "success"
					})
					await this.getBnbBalance()
				} catch (r) {
					// an error occurred, show an error message and go on
					new Toaster({
						code: r.code,
						message: r.message,
					})
				}
			}
		},
		async buy() {
			await this.connectWallet()
			if (this.amount_to_buy && +this.amount_to_buy) {
				let bnb_amount = (BigInt(+this.amount_to_buy * 10 ** 18)).toString();
				this.ico.methods.buy(this.referral_code !== "" ? this.referral_code : `0x${'0'.repeat(40)}`).send({
					from: this.connected_as,
					value: bnb_amount,
					gas: 1000000,
					gasPrice: 10000000000
				})
					.on("transactionHash", (tx: string) => {
						// open a popup overlay
						this.overlay.tx = tx
						this.overlay.confirmations = this.overlay.time = 0
						this.overlay.open = true
					})
					.on("confirmation", (confirmations: number) => {
						if (this.overlay.open) {
							this.overlay.confirmations = confirmations
						}
					})
					.on("receipt", () => {
						// transaction confirmed
						this.overlay.open = false
						// send a notification stating a successful transaction
						new Toaster({
							title: `Melodity $MELD bought!`,
							message: `You have successfully purchased ${this.amount_to_buy} BNB of Melodity $MELD
								at the price of ${this.tiers[this.current_tier_index]?.rate} per BNB.
								<br>
								Explore your locks
								<a href="https://lock.melodity.org/" target="_blank" rel="noopener"
									class="text-indigo-200 font-semibold">here</a>.
							`,
							type: "success"
						})
					})
					.on("error", (r: any) => {
						let parsed_msg = JSON.parse(r.message.replace("Transaction has been reverted by the EVM:", ""))
						// an error occurred, show an error message and go on
						new Toaster({
							code: r.code,
							message: `Transaction has been reverted due to an internal error, check it
								<a href="https://${testnet ? "testnet." : ""}bscscan.com/tx/${parsed_msg.transactionHash}"
									target="_blank" rel="noopener" class="text-indigo-200 font-semibold">here</a>.
							`,
						})
						this.overlay.open = false
					})
			}
		},
		async getBnbBalance() {
			this.max_bnb = this.renderNumber(await this.web3.eth.getBalance(this.connected_as))
		},
		async computeTiers() {
			this.tiers = (await Promise.all([
				await this.ico.methods.paymentTier(0).call(),
				await this.ico.methods.paymentTier(1).call(),
				await this.ico.methods.paymentTier(2).call(),
				await this.ico.methods.paymentTier(3).call(),
				await this.ico.methods.paymentTier(4).call(),
			])).map(v => ({
				rate: +v["rate"],
				lower_bound: this.renderNumber(v["lowerLimit"]),
				upper_bound: this.renderNumber(v["upperLimit"])
			}))

			let sold: any = (this.renderNumber(await this.ico.methods.distributed().call()))
				.replaceAll(",", "")
			let [sold_integer, sold_decimal] = sold.replaceAll(",", "").split(".")
			sold_decimal = sold_decimal.padEnd(18, "0")

			this.sold = `${this.renderNumber(sold_integer || "0.00", 0)}${sold_decimal.substr(0, 6)}`
			sold = +sold

			let current_tier = this.tiers.filter(
				v =>
					+(v.lower_bound.replaceAll(",", ""))
					<=
					+(this.sold.replaceAll(",", ""))
					&&
					+(v.upper_bound.replaceAll(",", ""))
					>
					+(this.sold.replaceAll(",", ""))
			)[0]
			this.current_tier_index = this.tiers.indexOf(current_tier)
			this.phase = this.current_tier_index + 1

			this.progressPercentage = (
					sold
					-
					+(current_tier.lower_bound.replaceAll(",", ""))
				)
				/
				(
					+(current_tier.upper_bound.replaceAll(",", ""))
					-
					+(current_tier.lower_bound.replaceAll(",", ""))
				)
				* 100
		}
	},
	watch: {
		theme: function () {
			this.theme === "light"
				? document.querySelector("html")!.classList.remove("dark")
				: document.querySelector("html")!.classList.add("dark")
		},
	},
	computed: {
		isTestnet() {
			return testnet
		},
		progressWidth() {
			return {
				width: `${this.progressPercentage}%`
			}
		},
		currentTierSoldMELD() {
			if (this.sold && this.tiers[this.current_tier_index]?.lower_bound) {
				let [previous_tier_integer,] = this.tiers[this.current_tier_index]?.lower_bound
					.replaceAll(",", "")
					.split(".")

				let [sold_integer, sold_decimal] = this.sold.replaceAll(",", "").split(".")
				sold_decimal = sold_decimal.padEnd(18, "0")
				sold_integer = (+sold_integer - +previous_tier_integer).toString()

				return `${this.renderNumber(sold_integer, 0)}${sold_decimal.substr(0, 6)}`
			}
			return "0.00"
		},
		MeldBuying() {
			return this.tiers[this.current_tier_index]?.rate * +(this.amount_to_buy.replaceAll(",", "")) || "0.00"
		}
	},
	created() {
		this.provider_options = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						56: 'https://bsc-dataseed1.binance.org',
						97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
					},
					network: 'binance',
					chainId: testnet ? 97 : 56,
				}
			},
			"custom-binancechainwallet": {
				display: {
					logo: require("@/assets/images/binance.png"),
					name: "Binance Chain Wallet",
					description: "Connect to your Binance Chain Wallet"
				},
				package: true,
				connector: async () => {
					let provider = null;
					if (typeof window.BinanceChain !== 'undefined') {
						provider = window.BinanceChain;
						try {
							await provider.request({method: 'eth_requestAccounts'})
						} catch (error) {
							throw new Error("User Rejected");
						}
					} else {
						throw new Error("No Binance Chain Wallet found");
					}
					return provider;
				}
			},
		}
		this.web3_modal = new Web3Modal({
			network: "binance",
			cacheProvider: !testnet, // optional
			providerOptions: this.provider_options // required
		})
		this.web3 = new web3(testnet ? "https://data-seed-prebsc-1-s1.binance.org:8545/" : "https://bsc-dataseed1.binance.org")
		this.ico = new this.web3.eth.Contract(ico_abi, ico_address)
		this.computeTiers()
		setInterval(this.computeTiers, 20000)
	},
	mounted() {
		//init theme
		document.querySelector("html")!.classList.contains('dark') ? this.theme = "light" : this.theme = "dark"

		//set referral from url
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("ref")) {
			this.referral = true
			this.referral_code = urlParams.get("ref")!;
		}
	},
});
