
import Web3 from "web3";
import Toaster from "@/composition/toaster";
import {defineComponent} from 'vue';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import TransactionOverlay from "@/components/TransactionDialog.vue";
import Footer from "@/components/Footer.vue";

const web3 = require("web3")
const ico_abi = require("@/assets/ico.json")
const testnet = false

// REAL SMART CONTRACT ADDRESS: 0xEde62Ac08D0Be6eF937c7bBda0C171b4c4432214
// TESTNET SMART CONTRACT ADDRESS: 0x6375e1fC0111D48bc4eB8f3f7023665f394750ea
// TESTNET SMART CONTRACT ADDRESS W/O time locks: 0xc652b14fDe48C3d6782FA7FA70a68c52681D3B12
const ico_address = testnet ? "0xc652b14fDe48C3d6782FA7FA70a68c52681D3B12" : "0xEde62Ac08D0Be6eF937c7bBda0C171b4c4432214"

export default defineComponent({
	name: "GenerateReferral",
	components: {Footer, TransactionOverlay},
	data: () => ({
		web3: {} as Web3,
		theme: "light",
		referral: "",
		supply_selected: 50,
		connected_as: "",
		web3_modal: {} as any,
		connected: false,
		meld_earned: "0.00",
		perc_range: 500,
		total_supply: 0.5,
		user_supply: 0.250,
		partner_supply: 0.250,
		generated: false,
		ico: {} as any,
		provider_options: {},
		overlay: {
			open: false,
			confirmations: 0,
			tx: "",
			time: 0,
			interval: -1
		},
	}),
	methods: {
		prettyNumber(x: number) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		renderNumber(value: any, allowed_decimals = 18) {
			// if the provided value is not a string stringify it
			if (!(value instanceof String)) {
				value = value.toString()
			}
			let integer = "0"
			// check if the value has at least an integer part, in case it has one, prettify it
			if (value.length > allowed_decimals) {
				integer = this.prettyNumber(value.substr(0, value.length - allowed_decimals))
			}
			let decimals = "00"
			// check if the value has integer part
			if (value.length > allowed_decimals) {
				// if it has retrieve only the decimal part
				decimals = value.substr(value.length - allowed_decimals)
			} else {
				// otherwise it is a decimal number, take it all
				decimals = `${"0".repeat(allowed_decimals - value.length)}${value}`
			}
			for (let i = decimals.length - 1; i >= 2; i--) {
				// remove last zeros if any
				if (decimals[i] === "0") {
					decimals = decimals.slice(0, -1)
				} else {
					break
				}
			}
			return `${integer}.${decimals.substr(0, decimals.length > 12 ? 12 : decimals.length)}`
		},
		copyReferralToClipboard() {
			const promise = navigator.clipboard.writeText(this.referral)
			promise.then(
				() => {
					new Toaster({
						title: `Copied to Clipboard`,
						message: ``,
						type: "success"
					})
				},
				(e) => console.error('error copying referral: ', e)
			)
		},
		async generate() {
			await this.connectWallet()
			// align with 18 decimals +2 position for percentages
			let user_supply_percent = BigInt(this.user_supply / this.total_supply * 10 ** 20),
				partner_supply_percent = BigInt(this.partner_supply / this.total_supply * 10 ** 20)

			this.ico.methods.createReferral(user_supply_percent, partner_supply_percent).send({
				from: this.connected_as,
				gas: 250000,
				gasPrice: 10000000000
			}).on("transactionHash", (tx: string) => {
				// open a popup overlay
				this.overlay.tx = tx
				this.overlay.confirmations = this.overlay.time = 0
				this.overlay.open = true
			})
				.on("confirmation", (confirmations: number) => {
					if (this.overlay.open) {
						this.overlay.confirmations = confirmations
					}
				})
				.on("receipt", () => {
					// transaction confirmed
					this.overlay.open = false

					this.referral = this.connected_as
					this.generated = true

					// send a notification stating a successful transaction
					new Toaster({
						title: `Referral created`,
						message: `
								You have successfully redeemed your referral prize!
							`,
						type: "success"
					})
				})
				.on("error", (r: any) => {
					let parsed_msg = JSON.parse(r.message.replace("Transaction has been reverted by the EVM:", ""))
					// an error occurred, show an error message and go on
					new Toaster({
						code: r.code,
						message: `Transaction has been reverted due to an internal error, check it
								<a href="https://${testnet ? "testnet." : ""}bscscan.com/tx/${parsed_msg.transactionHash}"
									target="_blank" rel="noopener" class="text-indigo-200 font-semibold">here</a>.
							`,
					})
					this.overlay.open = false
				})
		},
		calculatePercentageFromSlider() {
			const _user_supply = ((this.perc_range / 1000) * this.total_supply).toFixed(3)
			this.partner_supply = +(this.total_supply - +_user_supply).toFixed(3)
			this.user_supply = +((+_user_supply).toFixed(3))
		},
		onUserInputChange(value: number) {
			if (value.toString().length <= 5 && (0 <= value && value <= this.total_supply)) {
				let _partner_supply = this.total_supply - value
				let _perc_range = (value / this.total_supply) * 1000
				_perc_range = _perc_range >= 1000 ? 1000 : _perc_range

				this.partner_supply = +_partner_supply
				this.perc_range = +_perc_range
				this.user_supply = +value
			}
		},
		onPartnerInputChange(value: number) {
			if (value.toString().length <= 5 && (0 <= value && value <= this.total_supply)) {
				let _user_supply = this.total_supply - value
				let _perc_range = (value / this.total_supply) * 1000
				_perc_range = _perc_range >= 1000 ? 1000 : _perc_range

				this.partner_supply = +value
				this.perc_range = +_perc_range
				this.user_supply = +_user_supply
			}
		},
		async redeem() {
			await this.connectWallet()

			this.ico.methods.redeemReferralPrize().send({
				from: this.connected_as,
				gas: 250000,
				gasPrice: 10000000000
			}).on("transactionHash", (tx: string) => {
				// open a popup overlay
				this.overlay.tx = tx
				this.overlay.confirmations = this.overlay.time = 0
				this.overlay.open = true
			})
				.on("confirmation", (confirmations: number) => {
					if (this.overlay.open) {
						this.overlay.confirmations = confirmations
					}
				})
				.on("receipt", () => {
					// transaction confirmed
					this.overlay.open = false

					this.referral = this.connected_as
					this.generated = true

					// send a notification stating a successful transaction
					new Toaster({
						title: `Referral created`,
						message: `
								You have successfully created your referral code!
								<br>
								You can now share your code and earn bonus by referring people.
							`,
						type: "success"
					})
				})
				.on("error", (r: any) => {
					let parsed_msg = JSON.parse(r.message.replace("Transaction has been reverted by the EVM:", ""))
					// an error occurred, show an error message and go on
					new Toaster({
						code: r.code,
						message: `Transaction has been reverted due to an internal error, check it
								<a href="https://${testnet ? "testnet." : ""}bscscan.com/tx/${parsed_msg.transactionHash}"
									target="_blank" rel="noopener" class="text-indigo-200 font-semibold">here</a>.
							`,
					})
					this.overlay.open = false
				})
		},
		async connectWallet() {
			if (this.connected_as === "") {
				// show web3 modal popup
				const provider = await this.web3_modal.connect();
				// init provider and contract instance
				this.web3 = new web3(provider)
				this.ico = new this.web3.eth.Contract(ico_abi, ico_address)
				// try to connect to a wallet
				try {
					let r;
					if (!!provider.wc) {
						r = await this.web3.eth.getAccounts()
					} else {
						r = await this.web3.eth.requestAccounts()
					}
					// mark the user as connected and save the wallet address
					this.connected = true
					this.connected_as = r[0]
					// send a notification stating a successful connection
					new Toaster({
						title: `Wallet connected!`,
						message: `Successfully connected as ${r[0]}`,
						type: "success"
					})

					let referral = await this.ico.methods.referrals(this.connected_as).call()
					referral = {
						prize: referral["prize"],
						referredPrize: referral["referredPrize"],
						referrerPrize: referral["referrerPrize"]
					}

					this.user_supply = +(+referral["referrerPrize"] / 1e18).toString()
					this.partner_supply = +(+referral["referredPrize"] / 1e18).toString()

					if (this.user_supply === this.partner_supply && this.user_supply === 0) {
						this.user_supply = 0.25
						this.partner_supply = 0.25
					}

					this.referral = this.connected_as
					this.generated = !(referral.referredPrize === "0" && referral.referrerPrize === "0")

					this.meld_earned = this.generated ? ((prize): string => {
						let [sold_integer, sold_decimal] = this.renderNumber(prize)
							.replaceAll(",", "")
							.split(".")
						sold_decimal = sold_decimal.padEnd(18, "0")

						return `${this.renderNumber(sold_integer || "0.00", 0)}${sold_decimal.substr(0, 6)}`
					})(referral.prize) : "0.00"
				} catch (r) {
					// an error occurred, show an error message and go on
					new Toaster({
						code: r.code,
						message: r.message,
					})
				}
			}
		},
	},
	computed: {
		sliderFill() {
			return {
				backgroundSize: `${this.perc_range / 10}% 100%`,
			}
		},
		referralLink() {
			return `https://ico.melodity.org/?ref=${this.referral}`
		}
	},
	created() {
		this.provider_options = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						56: 'https://bsc-dataseed1.binance.org',
						97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
					},
					network: 'binance',
					chainId: testnet ? 97 : 56,
				}
			},
			"custom-binancechainwallet": {
				display: {
					logo: require("@/assets/images/binance.png"),
					name: "Binance Chain Wallet",
					description: "Connect to your Binance Chain Wallet"
				},
				package: true,
				connector: async () => {
					let provider = null;
					if (typeof window.BinanceChain !== 'undefined') {
						provider = window.BinanceChain;
						try {
							await provider.request({method: 'eth_requestAccounts'})
						} catch (error) {
							throw new Error("User Rejected");
						}
					} else {
						throw new Error("No Binance Chain Wallet found");
					}
					return provider;
				}
			},
		}
		this.web3_modal = new Web3Modal({
			network: "binance",
			cacheProvider: !testnet, // optional
			providerOptions: this.provider_options // required
		})
		this.web3 = new web3(testnet ? "https://data-seed-prebsc-1-s1.binance.org:8545/" : "https://bsc-dataseed1.binance.org")
		this.ico = new this.web3.eth.Contract(ico_abi, ico_address)
		this.connectWallet()
	},
	mounted() {
		//init theme
		document.querySelector("html")!.classList.contains('dark') ? this.theme = "light" : this.theme = "dark"

		//init percentage
		this.calculatePercentageFromSlider();
	},
})
