<template>
	<div class="flex flex-col justify-center w-full">
		<div class="grid grid-cols-6 cursor-pointer group transition-all duration-300" :class="headerClass"
		     @click="toggle">
			<div v-if="hasIcon" class="flex items-center" :class="iconClasses.wrapper">
				<slot name="icon"></slot>
			</div>
			<div :class="titleClasses.wrapper">
				<slot name="title">
					<h3 class="font-semibold text-lg items-center">
						{{ title || "Collapsible title" }}
					</h3>
				</slot>
			</div>
			<div v-if="false" class="flex items-center" :class="arrowClasses.wrapper">
				<div class="transition-all duration-300 origin-center ease-in flex items-center justify-center"
				     :class="arrowClasses.rotation_animation">
					<slot name="arrow">
						<i class='bx bx-chevron-right'></i>
					</slot>
				</div>
			</div>
		</div>
		<div v-if="alignContent" class="grid grid-cols-6 relative">
			<div
				class="col-span-5 col-start-2 flex flex-col justify-center max-h-0 overflow-hidden transition-all
                    duration-300 ease-in"
				:class="contentClasses"
				ref="content">
				<slot name="content"></slot>
			</div>
		</div>
		<div v-else
		     class="flex flex-col justify-center max-h-0 overflow-hidden transition-all
                relative duration-300 ease-in"
		     :class="contentClasses"
		     ref="content">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "Collapsible",
	props: {
		title: String,
		iconPlacement: {
			type: String,
			default: "center",
			validator: (v) => {
				return ["start", "center", "end"].includes(v)
			}
		},
		arrowPlacement: {
			type: String,
			default: "end",
			validator: (v) => {
				return ["start", "center", "end"].includes(v)
			}
		},
		alignContent: {
			type: Boolean,
			default: true
		},
		sidebarGroup: {
			type: Boolean,
			default: false
		},
		headerClass: {
			type: String,
			default: ""
		},
		hasIcon: {
			type: Boolean,
			default: true
		},
		modelValue: Boolean
	},
	emits: ["collapse", "open", "headerClick", "update:modelValue"],
	data: () => ({
		local_state: false
	}),
	methods: {
		toggle() {
			this.$emit("headerClick")

			if (this.$refs.content.style.maxHeight) {
				this.$emit("collapse")
				this.local_state = false
				this.$emit("update:modelValue", false)

				this.$refs.content.style.maxHeight = null
			} else {
				this.$emit("open")
				this.local_state = true
				this.$emit("update:modelValue", true)

				let factor = this.$refs.content.childElementCount > 1 ? this.$refs.content.childElementCount : 2

				this.$refs.content.style.maxHeight = `${this.$refs.content.scrollHeight * factor}px`;
			}
		},
	},
	computed: {
		arrowClasses() {
			return {
				wrapper: `justify-${this.arrowPlacement}`,
				rotation_animation: {
					"rotate-0": !this.modelValue,
					"rotate-90": this.modelValue
				}
			}
		},
		iconClasses() {
			return {
				wrapper: `justify-${this.iconPlacement}`,
			}
		},
		titleClasses() {
			return {
				wrapper: this.hasIcon ? `col-span-4` : `col-span-5`
			}
		},
		contentClasses() {
			return this.sidebarGroup ? "pt-2" : ""
		},
	},
	watch: {
		modelValue(v) {
			if(v !== this.local_state) {
				this.toggle()
			}
		}
	},
	mounted() {
		if(this.modelValue) {
			this.$emit("open")
			this.local_state = true

			this.$refs.content.style.maxHeight = `1000px`;
		}
	}
}
</script>
