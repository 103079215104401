
import {defineComponent} from 'vue'
import Overlay from "@/components/Overlay.vue";

export default defineComponent({
	name: "TransactionOverlay",
	components: {Overlay},
	emits: [
		"update:open",
		"update:confirmations",
		"update:time",
	],
	props: {
		confirmations: Number,
		tx: {
			type: String,
			default: "",
		},
		time: {
			type: Number,
			default: 0,
		},
		open: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		interval: {} as any,
	}),
	computed: {
		hrefTransaction() {
			return `https://testnet.bscscan.com/tx/${this.tx}`
		}
	},
	watch: {
		open(newVal) {
			if (newVal) { //modal opened
				this.$emit('update:time', 0);
				this.$emit('update:confirmations', 0);

				setTimeout(() => {
					if (this.confirmations === 0) {
						this.$emit('update:confirmations', this.confirmations + 1)
					}
				}, 5000)

				setTimeout(() => {
					if (this.confirmations === 1) {
						this.$emit('update:confirmations', this.confirmations + 1)
					}
				}, 10000)

				this.interval = setInterval(() => {
					this.$emit('update:time', this.time + 1)
				}, 1000)
			} else { //modal closed
				clearInterval(this.interval)
			}
		}
	},
})
