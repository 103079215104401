import {createRouter, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'
import Home from '../views/Home.vue'
import GenerateReferral from "@/views/GenerateReferral.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/referral',
    name: 'GenerateReferral',
    component: GenerateReferral
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms&Conditions',
    component: TermsAndConditions
  }
] as Array<RouteRecordRaw>

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
} as RouterOptions)

export default router
