import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Cleave from 'cleave.js';
import {Toastify} from "@/composition/toaster";
// boxicons
import "boxicons/css/boxicons.min.css"
import "boxicons/css/animations.css"
import "boxicons/css/transformations.css"

// tailwind css
import "./assets/main.scss"

import './assets/tailwind.css'


//INIT THEME
// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
} else {
    document.documentElement.classList.remove('dark')
}


const app = createApp(App)
app.config.globalProperties.$toastify = Toastify

app.directive('cleave', {
    mounted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: (el) => {
        const event = new Event('input', {bubbles: true});
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }
})

app.use(router).mount('#app')
