<template>
	<footer
		class="footer flex flex-col justify-center items-center items-baseline text-white select-none py-12 md:px-8 px-8">
		<div
			class="col-span-full w-full md:p-8 px-3 py-6 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 xl:gap-20 md:gap-12">
			<div v-for="(elem, id) of Object.entries(footer)" :key="id" class="xl:ml-0 lg:ml-12 lg:text-left mt-8 lg:mt-0">
				<h4 class="font-semibold text-xl font-semibold text-[#B5B2FF] font-poppins md:text-left text-center">
					{{ elem[0].split("_").map(v => `${v[0].toUpperCase()}${v.substr(1)}`).join(" ") }}
				</h4>
				<ul class="mt-8 font-thin font-poppins md:text-left text-center">
					<li v-for="(e, i) of elem[1]" :key="i">
						<a v-if="e.url" :href="e.url" target="_blank" rel="noopener">
							{{ e.label }}
						</a>
						<div v-else :onclick="e.click" class="cursor-pointer">
							{{ e.label }}
						</div>
					</li>
				</ul>
			</div>
		</div>

		<FeaturedIcons/>

		<div class="mt-8 flex items-center justify-center col-span-full mx-auto">
			<a class="rounded-full px-6 py-2 bg-[#B5B6FF]" href="mailto:support@melodity.org">Support</a>
		</div>

	</footer>
</template>

<script>
import FeaturedIcons from "@/components/FeaturedIcons";

export default {
	name: "Footer",
	components: {FeaturedIcons},
	data: () => ({
		footer: {
			instructions: [
				{
					label: "1. Connect your wallet",
				},
				{
					label: "2. Insert the amount",
				},
				{
					label: "3. Buy $MELD",
				},
			],
			info: [
				{
					label: "MELD Phases",
				},
				{
					label: "MELD Tokenomics",
					url: "https://melodity.org/#tokenomics",
				},
				{
					label: "MELD Locks",
					url: "https://docs.melodity.org/faq/lock-explanation#what-are-locks",
				},
			],
			docs: [
				{
					label: "Presentation",
					url: "https://docsend.com/view/e9knzicbry3ncaca",
				},
				{
					label: "Whitepaper",
					url: "https://docsend.com/view/fpxvfwmhtnq38gi6",
				},
				{
					label: "Documentation",
					url: "https://docs.melodity.org/",
				},
				{
					label: "Terms & Conditions",
					url: "/terms-and-conditions",
				},
			],
			social: [
				{
					label: "Telegram",
					url: "https://t.me/Melodity_Official_Community",
				},
				{
					label: "Twitter",
					url: "https://twitter.com/meloditytoken",
				},
				{
					label: "CoinMarketCap",
					url: "https://coinmarketcap.com/currencies/melodity/",
				},
			],
			links: [
				{
					label: "Melodity",
					url: "https://melodity.org/",
				},
				{
					label: "Do-inc",
					url: "https://do-inc.co/",
				},
			],
		}
	})
}
</script>

<style scoped>

</style>
