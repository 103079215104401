<template>
	<div class="md:col-span-8 md:col-start-3 col-span-full col-start-1 flex flex-col relative mx-auto">
		<div class="flex items-center justify-center flex-wrap md:flex-nowrap text-gray-800 mt-10">
			<div v-for="(elem, id) of featuredIcons" :key="id">
				<a class="rounded-full h-28 w-28 md:h-28 md:w-28 flex-col items-center justify-center px-2 md:m-2"
				   :href="elem.url" target="_blank" rel="noopener"
				   :class="['flex', elem?.bullet && elem.bullet ? 'bg-white' : '']">
					<div v-if="elem.desc" class="text-[#F9CFFF] font-poppins">
						<span>{{ elem.desc }}</span>
					</div>
					<img v-if="elem.pic" :src="elem.pic" :alt="elem.alt" class="object-contain rounded-md"/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "featuredIcons",
	data: () => ({
		featuredIcons: [
			{
				pic: require('@/assets/featured/2_bscscan.png'),
				url: "https://bscscan.com/address/0x13E971De9181eeF7A4aEAEAA67552A6a4cc54f43",
				alt: "Melodity (MELD) rating bscscan",
				bullet: false
			},
			{
				pic: require('@/assets/featured/3_cmc.png'),
				url: "https://coinmarketcap.com/currencies/melodity/",
				alt: "Melodity (MELD) rating cmc",
				bullet: false
			},
			{
				pic: require('@/assets/featured/4_coingecko.png'),
				url: "https://www.coingecko.com/en/coins/melodity",
				alt: "Melodity (MELD) rating coingecko",
				bullet: false
			},
			{
				pic: require('@/assets/featured/certik.png'),
				desc: "Audit",
				url: "https://www.certik.com/projects/melodity",
				alt: "certik",
				bullet: false,
			},
			{
				pic: require('@/assets/featured/interfi.png'),
				url: "https://github.com/interfinetwork/project-kyc-verification/blob/main/Melodity_KYCCertificate_InterFi.pdf",
				desc: "KYC",
				alt: "interfi",
				bullet: false,
			},
		]
	})
}
</script>

<style scoped>

</style>
