
import {defineComponent} from 'vue'

export default defineComponent({
	name: "Overlay",
	props: {
		open: {
			type: Boolean,
			default: false,
			required: true,
		},
	},
	computed: {
		transactionClass() {
			return {
				container: this.open ? `opacity-1` : `opacity-0`
			}
		},
	},
})
